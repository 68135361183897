// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/search.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/search.tsx");
  import.meta.hot.lastModified = "1705757833246.3062";
}
// REMIX HMR END

import { Suspense, useState } from "react";
import SearchInput from "./search-input";
import SearchResults from "./search-results";
export default function Search(props) {
  _s();
  const [query, setQuery] = useState("");
  return <div className="flex flex-col justify-start items-start gap-6 w-full">
      <SearchInput onQuery={setQuery} />
      <Suspense fallback={<div>Loading...</div>}>
        {query && <SearchResults party={props.party} room={props.room} query={query} />}
      </Suspense>
    </div>;
}
_s(Search, "HYX2QbDDdTtlu7GfoQbAPZOIM6k=");
_c = Search;
var _c;
$RefreshReg$(_c, "Search");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;